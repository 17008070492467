import React, { Component, useContext } from "react";
import { AppContext } from "../App";

export default function LinksRow({ linksArray }) {
  const strapiURL = useContext(AppContext)
  
  console.log(linksArray)
  if (!linksArray || !linksArray.data ) {
    // console.log('errror')
      return null; 
  }

  return (
    <ul className="list-group">
      {linksArray.data.map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="row">
              <div className="col-lg-6">
                  {item?.attributes?.Name}
              </div>
              <div className="col-lg-6">
                  {item?.attributes?.Description}
              </div>
              <div className="col-lg-12">
                  <a href={item?.attributes?.Link} target="_blank" rel="noopener noreferrer">
                  http://{item?.attributes?.Link}
                  </a>
              </div>
              <div className="col-lg-12">
              {item?.attributes && item?.attributes?.manufacturers?.data?.map((manufacturer) => (
                  <span key={manufacturer.id} className="badge badge-info">{manufacturer.attributes.Name}</span>
                ))}
              </div>
              
          </div>
        </li>
      ))}
    </ul>
  );
}
