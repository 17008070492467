import React, { Component, useContext } from "react";
import { AppContext } from "../App";


export default function BlogListsBlog({ bloglistsblog = [] }) {

    const strapiURL = useContext(AppContext)
    if (!bloglistsblog ) {
        return null; 
    }

    

return (
  <>
    {bloglistsblog.map((item, index) => {
      let imageUrl;
      if (
        item && 
        item.attributes && 
        item.attributes.Photos && 
        item.attributes.Photos.data && 
        item.attributes.Photos.data.length > 0
      ) {
        
        const randomIndex = Math.floor(Math.random() * item.attributes.Photos.data.length);
        const photo = item.attributes.Photos.data[randomIndex];
        // let imageUrl = undefined;

        console.log(photo)
        if (
          photo.attributes && 
          photo.attributes.formats && 
          photo.attributes.formats.thumbnail
        ) {
          imageUrl = photo.attributes.formats.thumbnail.url;
          // console.log(imageUrl)
          imageUrl = `${strapiURL}${imageUrl}`;
          // console.log(imageUrl)
        }
        console.log('ok')
        console.log(imageUrl)
      }

      return (
        <div className="osahan-card">
          <a href={`/blog/${item?.id}`}>
            <img className="img-fluid" src={imageUrl} alt=""/>
            <div className="osahan-card-body mt-3">
                <h6 className="text-white mb-1">{item?.attributes?.Title} </h6>
                <p className="m-0 text-white-50"><i className="feather-user text-white"></i> {item?.attributes?.Date}</p>
            </div>
          </a>
        </div>
        
      );
    })}
  </>
);

}
