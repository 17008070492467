import React, { Component } from "react";
import ReactMarkdown from 'react-markdown';

export default function CollectionItemParametry({ d }) {

    const ignoredFields = ["Opis", "Name", "localizations","createdAt","updatedAt","publishedAt","Producent_ID","manufacturer","kind","Photos","locale",'Dzial_ID'];

    return (
        <div className="p-5 bg-dark">
            <div className="row">

                {d && d.attributes && Object.keys(d.attributes).map((key) => {
                    if (ignoredFields.includes(key)) {
                        return null; // Pominięcie pól z listy pomijalnych
                    }

                    const attributeValue = d.attributes[key];

                    if (!attributeValue) {
                        return null; // Pominięcie pól, które są puste lub nieistniejące
                    }

                    return (
                        <div className="col-lg-4 col-md-4" key={key}>
                            <div className="list-icon mb-3">
                                <p className="text-white-50 mb-1">{key}</p>
                                <p className="text-white">{d.attributes[key]}</p>
                            </div>
                        </div>
                    );
                })}

        
            </div>
            <hr/>
            <p className="mt-4">Opis</p>
            <p className="text-white-50 mb-0">
                <ReactMarkdown>
                {d && d.attributes && d.attributes.Opis ? d.attributes.Opis : 'Brak danych'}
                </ReactMarkdown>
            </p>
        </div>
    );
}
