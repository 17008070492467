import React from 'react'
import { Link } from 'react-router-dom'

export default function SiteHeader() {
  return (


      <section className="py-4 osahan-copyright">
         <div className="container">
            <div className="row">
               <div className="col-xl-12">
                  <div className="d-flex align-items-center justify-content-between">
                     <p className="m-0 text-white-50">Copyright © StareKomputery 2023 | Made with <i className="feather-heart text-danger"></i> by <a className="text-white" target="_blank" href="https://netbytebites.net">Netruner</a></p>
                     {/* <p className="m-0">
                        &nbsp; · &nbsp;
                        <a href="#" className="text-secondary">O mnie</a>
                        &nbsp; · &nbsp;
                     </p> */}
                  </div>
               </div>
            </div>
         </div>
      </section>

    
  )
}
