import React, { useEffect, useState, useContext, useMemo} from 'react'
import { AppContext } from "../App";
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import BlogListsBlog from '../components/BlogListsBlog';
import ReactMarkdown from 'react-markdown';

export default function CollectionItem() {
  const strapiURL = useContext(AppContext)
  const token = process.env.REACT_APP_API_KEY
  const headers = useMemo(() => ({
      headers: { Authorization: `Bearer ${token}` }
  }), [token]);

  const {id} = useParams();
  const [item, setItem] = useState(null);
  const [itemBlogsList, setItemBlogsList] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${strapiURL}/api/blogs/${id}`, { ...headers,
          params: {
            populate: '*', 
          }
        });
        setItem(response.data.data);
        try {
          const responseBlogs = await axios.get(`${strapiURL}/api/blogs`, { ...headers,
            params: {
              'sort[0]': `createdAt:DESC`, // Sortowanie malejąco po dacie
              'pagination[page]': 1,
              'pagination[pageSize]': 5,
              'populate': '*', 
            }
          });
          setItemBlogsList(responseBlogs.data.data);
          
        } catch (error1) {
          console.error(error1);
        }

      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
      }
    };
      fetchData();
    }, [headers, id, strapiURL]);

  
  return (
    <section className="py-5 news-page">
      <div className="container">   
        <div className="d-flex align-item-center title mb-3">
            <h5 className="m-0 font-weight-normal">Blog
            </h5>
            <div className="ml-auto d-flex align-items-center">
              <a className="mr-2 text-white-50" href="/"><i className="feather-chevron-left"></i> do głównej</a>
            </div>
        </div>
        <div className="row">

          <div className="col-lg-8">


            {
              item?.attributes?.Photos?.data?.length > 0 && (
                

                <CarouselProvider
                naturalSlideWidth={1000}
                naturalSlideHeight={750}
                totalSlides={item.attributes.Photos.data.length}
                isPlaying={true}
                interval={3000}
                className="carousel slide"
              >
                <Slider>
                  {item.attributes.Photos.data.map((photo, index) => (
                    <Slide key={photo?.id || index} index={index}>
                      <div className="osahan-slider">
                        <div className="bg-dark">
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              
                            <img
                                className="img-fluid"
                                src={`${strapiURL}${photo?.attributes?.formats?.large?.url || photo?.attributes?.formats?.medium?.url || photo?.attributes?.formats?.small?.url  || ''}`}
                                alt={photo?.attributes?.alternativeText}
                            />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slide>
                  ))}
                </Slider>
                <ButtonBack
                  className="carousel-control-prev"
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </ButtonBack>
                <ButtonNext
                  className="carousel-control-next"
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>          
                </ButtonNext>
              </CarouselProvider>



              )
            }

            <hr></hr>
            <p>Opublikowane {item?.attributes?.Date}</p>
            <p className="lead">{item?.attributes?.Title}</p>
            
            <p className="text-white-50">
              <ReactMarkdown>
                {item?.attributes?.Body}
              </ReactMarkdown>
            </p>


          </div>
          <div className="col-lg-4">
            <div className="d-flex align-item-center title mb-3 mt-5">
                <h5 className="m-0 font-weight-normal">Inne wpisy
                </h5>
                {/* <div className="ml-auto d-flex align-items-center">
                  <div className="dropdown ml-2">
                      <button className="btn btn-sm btn-outline-light">
                      View All
                      </button>
                  </div>
                </div> */}
            </div>

            <div className="row">
              <div className="col-lg-12">
                
                <BlogListsBlog bloglistsblog={itemBlogsList} />

                
              </div>
            </div>


          </div>
          



        </div>
      </div>
    </section>
    
  )
}
