import React, { Component, useContext } from "react";
import { AppContext } from "../App";

export default function HomepagePhoto({ d }) {
  
  const strapiURL = useContext(AppContext)
  
  let imageUrl;
  if (
      d && 
      d.attributes && 
      d.attributes.Photos && 
      d.attributes.Photos.data && 
      d.attributes.Photos.data.length > 0
  ) {
      // Losujemy pozycję z dostępnych obiektów
      const randomIndex = Math.floor(Math.random() * d.attributes.Photos.data.length);
      const photo = d.attributes.Photos.data[randomIndex];

      // Sprawdzamy czy wylosowany obiekt posiada potrzebne klucze
      if (
          photo.attributes && 
          photo.attributes.formats && 
          photo.attributes.formats.small
      ) {
          imageUrl = photo.attributes.formats.small.url;

          imageUrl = `${strapiURL}${imageUrl}`;
      }
  }

  // console.log(d.attributes.Photos.data);
  
  return (
  
    <div className="col-lg-12">
      <div className="osahan-card bg-dark1">
        <a href={`/collection/${d.id}`}>
          <img className="img-fluid" src={imageUrl} alt={d.attributes.Name}/>
          {/* <div className="bg-primary text-center p-1 text-white">{d.attributes.Name}</div> */}
          {/* <div className="osahan-card-body p-4">
            <h6 className="text-white mb-2">{d.attributes.Title}</h6>
            <p className="m-0 text-white-50">
              <i className="feather-user text-white"></i> StareKomputery • {d.attributes.Date}
            </p>
          </div> */}
        </a>
      </div>
    </div>
    // <a href={`/blog/${d.id}`} className="list-group-item list-group-item-action1">
    // <div className="d-flex w-100 justify-content-between">
    //   <h5 className="mb-1">{d.attributes.Name}</h5>
    //   <small></small>
    // </div>
    // <p className="mb-1 text-white">
      
    // </p>
    // {/* <small>Donec id elit non mi porta.</small> */}
    // </a>
  );
}



