import React, { Component, useContext } from "react";
import { AppContext } from "../App";
// import '../js/CollectionItemPhotos';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ButtonPlay } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

export default function CollectionItemPhotos({ d }) {
    const strapiURL = useContext(AppContext)

    if (!d || !d.attributes || !d.attributes.Photos || !Array.isArray(d.attributes.Photos.data)) {
      // console.log('errror')
      return null; 
    }

    return (
      <CarouselProvider
        naturalSlideWidth={1000}
        naturalSlideHeight={750}
        totalSlides={d.attributes.Photos.data.length}
        isPlaying={true}
        interval={3000}
        className="carousel slide"
      >
        <Slider>
          {d.attributes.Photos.data.map((photo, index) => (
            <Slide key={photo?.id || index} index={index}>
              <div className="osahan-slider">
                <div className="bg-dark">
                  <div className="row align-items-center">
                    <div className="col-lg-12">
                      
                    <img
                        className="img-fluid"
                        src={`${strapiURL}${photo?.attributes?.formats?.large?.url || photo?.attributes?.formats?.medium?.url || ''}`}
                        alt={photo?.attributes?.alternativeText}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          ))}
        </Slider>
        <ButtonBack
          className="carousel-control-prev"
        >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
        </ButtonBack>
        <ButtonNext
          className="carousel-control-next"
        >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>          
        </ButtonNext>
      </CarouselProvider>
    );
}
