import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import Modal from "react-modal";
import './styles/style.css';
// import './styles/custom.css';

Modal.setAppElement("#root");
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);
