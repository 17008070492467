import React, { Component, useContext } from "react";
import { AppContext } from "../App";

export default function CollectionBox({ d }) {
  const strapiURL = useContext(AppContext)
  
  let imageUrl;
  if (
      d && 
      d.attributes && 
      d.attributes.Photos && 
      d.attributes.Photos.data && 
      d.attributes.Photos.data.length > 0
  ) {
      // Losujemy pozycję z dostępnych obiektów
      const randomIndex = Math.floor(Math.random() * d.attributes.Photos.data.length);
      const photo = d.attributes.Photos.data[randomIndex];

      // Sprawdzamy czy wylosowany obiekt posiada potrzebne klucze
      if (
          photo.attributes && 
          photo.attributes.formats && 
          photo.attributes.formats.small
      ) {
          imageUrl = photo.attributes.formats.small.url;

          imageUrl = `${strapiURL}${imageUrl}`;
      }
  }
  // console.log(d.attributes.Photos)

  return (
    <div className="col-lg-3 col-md-6">
      <div className="osahan-card">
          <a href={`/collection/${d.id}`}>
            <img className="img-fluid" src={imageUrl} alt="" />
            <div className="osahan-card-body mt-3">
                <h6 className="text-white mb-1">{d.attributes.Name}  </h6>
                <p className="mb-0 text-white-50">{d.attributes.kind && d.attributes.kind.data && d.attributes.kind.data.attributes && d.attributes.kind.data.attributes.Name || '-'}</p>
                <div className="price mt-3">
                  <div className="bg-primary d-inline-block text-center px-2 py-1 text-white">{d.attributes.manufacturer.data.attributes.Name}</div>
                  {/* <s className="text-white-50 mx-1">$34.99</s> */}
                  {/* <span className="text-white">$27.99</span> */}
                </div>
            </div>
          </a>
      </div>
    </div>
  );
}
