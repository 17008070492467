import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { createContext } from "react";

import CookieConsent from "react-cookie-consent";
import SiteHeader from "./components/SiteHeader";
import SiteBottom from "./components/SiteBottom";
import Homepage from "./pages/Homepage.jsx";
import Collection from "./pages/Collection";
import CollectionItem from "./pages/CollectionItem";
import BlogItem from './pages/BlogItem';
import Links from './pages/Links';


export const AppContext = createContext();


function App() {

  const strapiURL = process.env.REACT_APP_STRAPI_BASE_URL;

  return (

    <AppContext.Provider value={strapiURL}>
      <Router>
        <div className="App">
          <SiteHeader />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/blog/:id" element={<BlogItem />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/collection/:id" element={<CollectionItem />} />
            <Route path="/links" element={<Links />} />
            {/* <Route path="/manufacturer/:id" element={<Manufacturer />} /> */}
            <Route path="*" element={<h1>No Page</h1>}/>
          </Routes>
          <SiteBottom />
          <CookieConsent>
            Ta strona wykorzystuje pliki cookie do personalizacji treści i analizy ruchu.
          </CookieConsent>
        </div>
      </Router> 
    </AppContext.Provider>
  );
}
 
export default App;
