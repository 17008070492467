import React from 'react'

export default function SiteHeader() {
  return (


    <nav className="navbar navbar-expand navbar-dark bg-dark osahan-nav-top p-0 px-2 sticky-top">
       <div className="container">
          <a className="navbar-brand mr-2" href="/"><img src="img/logo.svg" alt=""/></a>
          {/* <form className="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
             <div className="input-group">
                <input type="text" className="form-control shadow-none border-0" placeholder="Search..." aria-label="Search" aria-describedby="basic-addon2" />
                <div className="input-group-append">
                   <button className="btn btn-light" type="button">
                   <i className="feather-search"></i>
                   </button>
                </div>
             </div>
          </form> */}
          <ul className="navbar-nav ml-auto d-flex align-items-center">
         
           
             <li className="nav-item  mr-2">
              <a className="nav-link  pr-2 " href="/" role="button"  aria-haspopup="true" aria-expanded="false">Home</a>
             </li>
             <li className="nav-item  mr-2">
             <a className="nav-link  pr-2 " href="/collection" role="button"  aria-haspopup="true" aria-expanded="false">Zbiory</a>
             </li>
             <li className="nav-item  mr-2">
             <a className="nav-link  pr-2 " href="/links" role="button"  aria-haspopup="true" aria-expanded="false">Linki</a>
             </li>
      
          </ul>
       </div>
    </nav>

    
  )
}
