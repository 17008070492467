import React, { Component, useContext } from "react";
import { AppContext } from "../App";

export default function HomepageBlog({ d }) {
  
  const strapiURL = useContext(AppContext)

  let imageUrl;
  const imageUrlPath = d?.attributes?.Photos?.data?.[0]?.attributes?.formats?.small?.url;
  if(imageUrlPath) {
      imageUrl = `${strapiURL}${imageUrlPath}`
      
  }
  
  return (

      <div className="card mb-3">
        <a href={`/blog/${d.id}`} className="list-group-item list-group-item-action1">
          <div className="card text-center">
            <img className="img-fluid" src={imageUrl} alt=""/>
            <div className="card-body">
              <h5 className="card-title text-white">{d.attributes.Title}</h5>
              <p className="card-text">
                {d.attributes.Body.length > 100 ? d.attributes.Body.slice(0, 100) + ' ...' : d.attributes.Body}
              </p>
              <p className="card-text"><small className="text-muted">{d.attributes.Date}</small></p>
            </div>
          </div>
        </a>
      </div>
  );
}



