import React from 'react';

const ShareButton = () => {
  // Funkcja, która otwiera nowe okno z Facebook Sharerem
  const share = () => {
    const currentUrl = encodeURIComponent(window.location.href);
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
    window.open(facebookUrl, 'facebook-share-dialog', 'width=800,height=600');
  };

  return (
    <button className='btn btn-outline-secondary btn-sm btn-block' onClick={share}>Udostępnij na Facebooku</button>
  );
};

export default ShareButton;
